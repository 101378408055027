<template>
  <div class="box">
    <span v-if="label" class="subtitle is-spaced is-6 has-text-grey">
      {{ label }}
    </span>
    <div class="columns is-flex-end">
      <div class="column">
        <p class="title is-1">
          <router-link
            v-if="linkTo !== null"
            class="has-text-info"
            :to="linkTo"
          >
            {{ value }}
          </router-link>
          <span v-else class="has-text-info">
            {{ value }}
          </span>
        </p>
      </div>
      <div v-if="iconClass" class="column is-narrow">
        <span :class="`icon is-size-4 ${iconColor}`">
          <i :class="iconClass"></i>
        </span>
      </div>
      <div v-else-if="image" class="column is-narrow">
        <img
          height="24"
          width="24"
          :src="require('core/img/icons/percayso-logo.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryBox',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    iconClass: {
      type: String,
      required: false,
      default: () => null
    },
    iconColor: {
      type: String,
      required: false,
      default: () => 'has-text-info'
    },
    linkTo: {
      type: Object,
      required: false,
      default: () => null
    },
    image: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.is-flex-end
  align-items: flex-end
</style>
